<!-- 主页 -->
<template>
  <div class="page-main"  id="message">
    <div v-if="loading" class="login-loading">
      <van-loading type="spinner" vertical>
        加载中...
      </van-loading>
    </div>
    <div v-else class="nut-card">
      <!-- 主页 -->
      <div class="nut-title">
        <div class="nut-name">
          <van-icon name="manager-o" size="28" color="#333" @click="goUserInfo" />
          <div class="nut-name-desc">
            智能营养筛查评估系统
          </div>
        </div>
        <div class="nut-tabbar">
          <van-tabs v-model:active="active" class="tab-class" background="none" @change="onClickTab">
            <van-tab title="营养自筛" name="1" />
            <!-- <van-tab title="营养评估" name="2" /> -->
          </van-tabs>
        </div>
      </div>
      <div class="nut-module">
        <div class="nut-card">
          <div class="nut-item">
            <div class="item-attention">请选择你需要自筛的项目</div>
            <div class="attention-line" />
            <div class="module-list">
              <div class="module-item" v-for="item in dataList" :key="item.id" @click="goChat(item.id)">
                {{item.temp_name}}
              </div>
            </div>
            <div class="desc-line" />
            <div class="item-desc">点击上方筛查项目，快速进入自筛问答</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { setSession } from "../../utils/sessionUtils"
import CommonData from "../../utils/commonData"
import { Toast } from 'vant'
import item from "@/api/item/item"

export default {
  name: "history",
  components: {
  },
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      console.log('home=====>')
      //获取传入参数
      getItemList()
    });
    const getItemList = () => {
      const post_data = {
        temp_type: data.active,
        page_index: data.page_index,
        page_size: data.page_size
      }
      item.itemList(post_data).then(res=> {
        data.total = res.data.total
        data.dataList = res.data.data
        data.loading = false
      })
    }
    const goUserInfo = () => {
      $router.push({
          name: 'mine'
        })
    }
    const data = reactive({
      loading: true,
      active: 1,
      total: 0,
      page_index: 1,
      page_size: 10,
      dataList: []
    });
    const onClickTab = () => {
      console.log("onClickTab")
      console.log(data.active)
    }
    const goChat = id => {
      // 添加项目
      const post_data = {
        id: id
      }
      item.itemAdd(post_data).then(res => {
        const params = {
          id: id,
          launch_id: res.data.launch_id
        }
        $router.push({
          name: 'chat',
          query: params
        })
      })
      
    }
    const goHistory = () => {
      $router.push({
        name: 'history'
      })
    }

    return {
      ...toRefs(data),
      goUserInfo,
      onClickTab,
      goChat,
      goHistory,
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  background: #EEE;
  .login-loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nut-title {
    width: 100%;
    background: linear-gradient(to right,#E6F8FA, #DEFAEE);
    .nut-name {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0 20px;
      .nut-name-desc {
        width: calc(100% - 40px);
        text-align: center;
        font-size: 18px;
        line-height: 40px;
        color: #333;
      }
    }
    .nut-tabbar {
      width: 100px;
      .tab-class {
        :deep(.van-tabs__line) {
          background: #34C0C4;
        }
        :deep(.van-tab--active) {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
  .nut-module {
    .nut-card {
      margin-top: 40px;
      .nut-item {
        background: #fff;
        margin: 10px;
        padding: 20px;
        border-radius: 10px;
        font-size: 16px;
        .item-attention {
          font-weight: bold;
        }
        .attention-line {
          margin: 10px 0;
          border-top: thin solid #53BC7D;
        }
        .module-list {
          font-size: 12px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          .module-item {
            margin: 5px 5px 5px 0;
            padding: 10px;
            background: #ECF9F1;
            color: #4BBE79;
            border-radius: 20px;
          }
        }
        .desc-line {
          margin: 10px 0;
          border-top: thin solid #666;
        }
        .item-desc {
          font-size: 12px;
          color: #666;
        }
      }
    }
  }
}

</style>
